<script setup>
defineOptions({
    inheritAttrs: false,
});

const attrs = useAttrs();
const props = defineProps({
    modelValue: {
        type: [Boolean, String, Array],
        default: false,
    },
    // TODO use true/false value
    trueValue: {
        default: null,
    },
    falseValue: {
        default: null,
    },
    label: String,
    labelClass: String,
    wrapperClass: String,
    error: String,
    readonly: Boolean,
});

const emit = defineEmits(['update:modelValue']);

const checked = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
});

const labelClass = computed(() => {
    const classes = props.labelClass ?? '';

    if (props.readonly) {
        return `${classes} text-slate-600`;
    }

    return classes;
});
</script>

<template>
    <div :class="['form-check', wrapperClass]">
        <input
            ref="input"
            v-bind="attrs"
            v-model="checked"
            type="checkbox"
            :readonly="readonly"
            :disabled="attrs.disabled || readonly"
            class="form-check-input bg-white"
        />
        <label
            v-if="label"
            :class="['form-check-label', labelClass]"
            :for="attrs.id"
        >
            <slot name="icon" />
            <span>{{ label }}</span>
        </label>
    </div>

    <InputErrorMessage
        :error="error"
        :field-label="label"
        :field-name="attrs.name"
    />
</template>
